.content {
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    .content {
        min-height: calc(100vh - 56px);
    }
}
