.paper {
    margin: 18px 12px;
    padding: 0 12px;
}

@media screen and (min-width: 624px) {
    .paper {
        width: 600px;
        margin: 18px auto;
    }
}
@media screen and (min-width: 920px) {
    .paper {
        width: 900px;
        margin: 18px auto;
    }
}

.notion {
    margin-top: 12px !important;
}

.bottomContainer {
    margin-top: 32px;
    margin-bottom: 6px;
}

.logoContainer {
    display: block;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.logo {
    max-width: 200px;
    vertical-align: middle;
    width: 100%;
    padding: 5px;
}

.logo:hover {
    filter: contrast(70%);
}

@media all and (min-width: 600px) {
    .bottomContainer {
        width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .logoContainer {
        display: inline;
        width: 200px;
        margin-left: unset;
        margin-right: unset;
    }
}
