.stepContainer {
    margin-top: 18px;
}

.navigation {
    margin-top: 48px;
    margin-bottom: 12px;
}

.flex {
    display: flex;
}

.grow {
    flex-grow: 1;
}
