.flex {
    display: flex;
}

.grow {
    flex-grow: 1;
}

.alignRight {
    text-align: right;
}

.marginTop {
    margin-top: 12px;
}
