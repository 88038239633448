.tutorialBox {
    margin-left: 1.5em;
}

h1 {
    margin: 0;
    padding: 0.08em;
    text-align: center;
    background-color: #0f0f0f;
    color: #fff;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
