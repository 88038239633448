.content {
    flex-grow: 1;
}

.drawer {
    height: 100%;
    width: 300px;
}

.grow {
    flex-grow: 1;
}
