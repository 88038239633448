.container {
    margin: 24px 6px 36px;
}

.startOverContainer {
    margin-top: 24px;
}

.flex {
    display: flex;
}

.grow {
    flex-grow: 1;
}
