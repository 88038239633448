.margin {
    margin: 12px !important;
}

.map {
    margin: 12px 12px 36px;
    display: flex;
    align-items: center;
}

.map > * {
    margin: 4px;
}
