.logo {
    height: 48px;
    margin-top: 4px;
    margin-bottom: 4px;
}

.gutter {
    width: 48px;
}

@media all and (min-width: 600px) {
    .logo {
        height: 56px;
    }
}
