.search {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 320px;
    height: 32px;
    margin: 10px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
}
