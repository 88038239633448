.container {
    margin: 24px 6px 36px;
}

.startOverContainer {
    margin-top: 24px;
}

.flex {
    display: flex;
}

.grow {
    flex-grow: 1;
}

.avatarContainer {
    margin-top: 12px;
    margin-left: 6px;
}

.avatar {
    width: 90px !important;
    height: 90px !important;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.shopify {
    margin-top: 24px;
}
