.container {
    display: flex;
    flex-direction: row;
    height: inherit;
}

.mapContainer {
    display: flex;
    flex-grow: 1;
    width: 100%;
    margin-left: -35em;
}

.helperContainer {
    width: 35em;
    max-height: 60em;
    align-self: center;
    z-index: 1;
}

.map {
    position: inherit;
    flex-grow: 1;
}

.drawerPaper {
    top: unset !important;
    width: 240px;
}
